/*
  Resume Website project
  Author: Dan J. Hamilton
  Date:   15 February 2016

  Filename:         index.html
  Supporting files: modernizr-1.5.js, styles_main.css
*/
import React from "react";
import { Link } from "gatsby";

import SEO from "../../components/seo";
import "../../styles/resume_styles.css";

export default () => (
  <>
    <SEO title="Resume of Dan J. Hamilton" />

    <div className="all-content">
      <div className="nav">
        <ul>
          <li><Link to="/index-project/" title="Project Index">Project Index</Link></li>
          <li><Link to="/resume/" title="Welcome Page">Welcome Page</Link></li>
          <li><Link to="/resume/employmenthistory/" title="Resume">Resume</Link></li>
          <li><Link to="/resume/bio/" title="Biography">Biography</Link></li>
          <li><Link to="/resume/music/" title="Favorite Music">Favorite Music</Link></li>
          <li><Link to="/resume/food/" title="Favorite Food">Favorite Food</Link></li>
          <li><Link to="/resume/about/" title="About this Website">About this Website</Link></li>
        </ul>
      </div>

      <section className="resume-main-content">
        <div className="section-header">
          <h1>Biography</h1>
        </div>

        <h4>ACT I - CHILDHOOD</h4>
		 
        <p id="firstp">   
          I was born in New Haven, CT, while my father was finishing a Master's Degree at Yale University. 
          After university, my Dad began a long, distinguished actuarial career at Aetna Life and Casualty. 
          As a result, I grew up in the suburbs of Hartford.  I am the oldest of three and the only son in 
          a "traditional" family.
        </p>

 		    <h4>ACT II - MY YALE YEARS</h4>
		 
        <p>
          After 13 years of public schools, persistent parents, and some native ability, I too attended 
          Yale University. Yale was a glorious experience for me. After years of being the egghead, I was 
          finally in an environment where I fit in.

        </p>

        <p>
          In hindsight, I did not make the best use of my time in New Haven, but youth has not wisdom 
          at the moment.
        </p>

		    <h4>ACT III - THE ACTUARIAL EXPERIENCE</h4>
		 
        <p>
          After college, I continued in my father's footsteps, working in the insurance industry, and 
          studying to be an actuary.  Unlike my Dad, I only finished the exams through the associate level.
		    </p>
        <p>
          For 27-years, I worked for two life insurance companies and two life reinsurance companies in 
          three different states: Connecticut, Florida, Missouri, and then back to Florida.  A theme
          emerged, not intentionally, a steady progression to a smaller sized employer.
        </p>
		    <p>
          While working for RGA in Missouri, I had the opportunity to work and live in Hong Kong. It was there
          I met my wife.
        </p>

		    <h4>ACT IV - THE NEXT ACT</h4>

        <p>
          Today,  I have a much greater appreciation of who I am and what my strengths and weaknesses are. 
          I have finally grown up enough to set my own path.
        </p>
			  <p>
          I enrolled at Valencia College to study computer programming.  Currently, I continue my 
          studies through self-learning material online. The computer industry is a broad and complex
          field. I am not sure exactly where I fit in.  However, I am excited and looking forward to the future.
		    </p>

		    <p>
			    Just a few more steps before I take flight in my new career:
		    </p>

		    <div>
			    <ul>
				    <li>Continue education as this industry evolves at breakneck speed.</li>
            <li>Network in the local community.</li>
				    <li>Find internship or volunteer opportunities to develop my skills and establish myself.</li>
				    <li>Decide whether to work for someone or start my own company. The logo in the lower right
              corner on the Welcome Page is the current design if I go with my company.</li>
			    </ul>
		    </div>
      </section>
 
      <div className="footer">
         <div className="address">
            Winter Garden, FL &nbsp;34787
         </div>
      </div>

    </div>
  </>
)